import React from "react";
import PropTypes from "prop-types";
import { createBrowserHistory } from "history";
import configureStore from "../../store";
import { Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { AnimatePresence } from "framer-motion";

const history = createBrowserHistory();
const store = configureStore(history);

import routes from "../../packs/config/routes";

// Containers
import Login from "../../packs/containers/login/Login";
import AuthRoute from "../../packs/routes/AuthRoute";
import RescuePassword from "../../packs/containers/passwords/RescuePassword";
import ChangePassword from "../../packs/containers/passwords/ChangePassword";
import Home from "../../packs/containers/Home";
import Disclaimer from "../../packs/containers/Disclaimer";
import Policy from "../../packs/containers/Policy";
import BeforeContinuing from "../../packs/containers/BeforeContinuing";
import { default as ProfileForm } from "../../packs/containers/users/Form";
import Profile from "../../packs/containers/users/Profile";
import SurveyIndex from "../../packs/containers/surveys/SurveyIndex";
import Survey from "../../packs/containers/surveys/SurveyContainer";
import SurveyModuleDetail from "../../packs/containers/surveys/SurveyModuleDetail";
//import AdminHome from "../../packs/containers/admin/AdminHome";
import OrganizationHome from "../../packs/containers/organizations/OrganizationHome";
import Organization from "../../packs/containers/organizations/Organization";
import FolderHome from "../../packs/containers/folders/FolderHome";
import Folder from "../../packs/containers/folders/Folder";
import NotFound from "../../packs/containers/NotFound";
import ProcedureNotPassed from "../../packs/containers/ProcedureNotPassed";
import Proctoring from "../../packs/containers/proctoring/Proctoring";
import EmailsHome from "../../packs/containers/emails/EmailsHome";
import EmailsShow from "../../packs/containers/emails/EmailsShow";
import EmailsCreate from "../../packs/containers/emails/EmailsCreate";
import ConsentEdit from "../../packs/containers/consent/ConsentEdit";
import AdministratorsIndex from "../../packs/containers/administrators/AdministratorsIndex";
import LogsHome from "../../packs/containers/logs/LogsHome";
import ProceduresHome from "../../packs/containers/procedures/ProceduresHome";
import ProceduresShow from "../../packs/containers/procedures/ProceduresShow";
import ProceduresUserShow from "../../packs/containers/procedures/ProceduresUserShow";
import ProcedureClient from "../../packs/containers/procedures/ProcedureClient";
import ProcedureClientOld from "../../packs/containers/procedures/ProcedureClientOld";
import ProcedureEmails from "../../packs/containers/procedures/ProcedureEmails";
import RankingProfileIndex from "../../packs/containers/folders/RankingProfile";
//import ServerError from "./packs/containers/ServerError";

// Components
import Wrapper from "../../packs/components/Wrapper";

export default class App extends React.Component {
  render = () => {
    return (
      <Provider store={store} history={history}>
        <Router history={history}>
          <Wrapper history={history} store={store} csrfToken={this.props.csrfToken}>
            <AnimatePresence exitBeforeEnter>
              <Switch>
                {/** Public routes */}
                <Route
                  exact
                  path={routes.client.signin}
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path={routes.admin.signin}
                  render={(props) => <Login {...props} />}
                />
                <Route
                  path={routes.client.profileSignin}
                  component={(props) => <ProfileForm {...props} step={0} />}
                />
                <Route
                  path={routes.client.beforeContinuing}
                  component={BeforeContinuing}
                />
                <Route path={routes.client.disclaimer} component={Disclaimer} />
                <Route path={routes.client.policy} component={Policy} />
                <Route path={routes.client.checkproctoring} component={Proctoring} />
                <Route path={routes.client.procedure_not_passed} component={ProcedureNotPassed} />
                <Route
                  path={routes.client.rescuePassword}
                  component={RescuePassword}
                />
                <Route
                  path={routes.client.changePassword}
                  component={ChangePassword}
                />
                <Route
                  path={routes.admin.rankingProfile}
                  component={RankingProfileIndex}
                />                
                <Route
                  path={routes.client.procedure}
                  component={ProcedureClientOld}
                />
                {/** Private routes */}
                {/* Client routes */}
                <AuthRoute
                  exact
                  path={routes.client.survey_module}
                  component={SurveyModuleDetail}
                />
                <AuthRoute
                  exact
                  path={routes.client.home}
                  component={SurveyIndex}
                />
                <AuthRoute
                  exact
                  path={routes.client.surveys}
                  component={SurveyIndex}
                />
                <AuthRoute
                  exact
                  path={routes.client.survey}
                  component={Survey}
                />
                {/* Admin routes */}
                <AuthRoute
                  exact
                  path={routes.admin.folder}
                  component={Folder}
                />
                <AuthRoute
                  exact
                  path={routes.admin.procedures}
                  component={ProceduresHome}
                />
                <AuthRoute
                  exact
                  path={routes.admin.proceduresShow}
                  component={ProceduresShow}
                />
                <AuthRoute
                  exact
                  path={routes.admin.proceduresUserShow}
                  component={ProceduresUserShow}
                />
                <AuthRoute
                  exact
                  path={routes.admin.proceduresEmails}
                  component={ProcedureEmails}
                />
                <AuthRoute
                  exact
                  path={routes.admin.procedureConsentEdit}
                  component={ConsentEdit}
                />
                <AuthRoute
                  exact
                  path={routes.admin.surveyConsentEdit}
                  component={ConsentEdit}
                />
                <AuthRoute
                  exact
                  path={routes.admin.user}
                  component={Profile}
                />
                <AuthRoute
                  exact
                  path={routes.client.myProfile}
                  component={Profile}
                />
                <AuthRoute
                  exact
                  path={routes.admin.home}
                  component={OrganizationHome}
                />
                <AuthRoute
                  exact
                  path={routes.admin.organization}
                  component={Organization}
                />
                <AuthRoute
                  exact
                  path={routes.admin.organizationIndexEmails}
                  component={EmailsHome}
                />
                <AuthRoute
                  exact
                  path={routes.admin.emailsShow}
                  component={EmailsShow}
                /> 
                <AuthRoute
                  exact
                  path={routes.admin.emailsCreate}
                  component={EmailsCreate}
                /> 
                <AuthRoute
                  exact
                  path={routes.admin.emails}
                  component={EmailsHome}
                />  
                <AuthRoute
                  exact
                  path={routes.admin.folderIndex}
                  component={FolderHome}
                />
                <AuthRoute
                  exact
                  path={routes.admin.administrators}
                  component={AdministratorsIndex}
                />
                <AuthRoute
                  exact
                  path={routes.admin.logs}
                  component={LogsHome}
                />
                <Route component={NotFound} />
              </Switch>
            </AnimatePresence>
          </Wrapper>
        </Router>
      </Provider>
    );
  };

  static propTypes = {
    csrfToken: PropTypes.string,
  };
}
