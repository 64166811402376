import React, { Component } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";

// Actions
import { fetchSurvey } from "./actions";

// Components
import BubbleImageBg from "../../components/BubbleImageBg";
import BubbleBg from "../../components/BubbleBg";
import SurveyModulesContainer from "./components/SurveyModulesContainer";
import SurveyQuestionsContainer from "./components/SurveyQuestionsContainer";
import Congratulations from "../../containers/Congratulations";
import Spinner from "../../components/Spinner";

// Config
import { routes } from "../../config/routes";

//localize
import internalization from "../../config/localize"

class SurveyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finished: false,
      loading: true
    }
  }

  componentDidMount(){
    // internalization.setLanguage('pt');
    $(window).scrollTop(0);
    this.setState({loading: true})
    this.props.fetchSurvey(this.props.match.params.surveyToken, (data) => this._success(data));

  }

  _success(data){

    let root = document.documentElement;    
    root.style.setProperty("--primary-color", data.attributes.primary_color);
    root.style.setProperty("--secondary-color", data.attributes.secondary_color);

    if (Object.keys(data.attributes.modules).length === 1) {
      let module_key = Object.keys(data.attributes.modules)[0];
      this.props.history.push(`/survey/${this.props.match.params.surveyToken}/${module_key}`, {
        goBackUrl: routes.client.surveys
      });
    }

    if(data.attributes.modules[0].has_glass_effect){
      const root = document.querySelector('#o-root'); 
      root.classList.add('has-glass-effect');
      root.style.backgroundImage = `url(${data.attributes.image_url})`;
    }else {
      console.log("there's no glass effect");
    }

    this.setState({loading: false})

  }

  render() {
    return (
      <section className="o-wrapper">
        {
          this.state.loading && <Spinner />
        }
        {
          !this.state.loading && Object.keys(this.props.survey).length > 0 && (this.props.survey.progress === 100 || this.state.finished) &&
          <Congratulations title={internalization.survey.survey_finished_title} subtitle={internalization.survey.survey_finished_subtitle} buttonText={internalization.buttons.accept} handleClick={() => this.props.history.replace(routes.client.surveys)} />
        }
        {
          !this.state.loading && Object.keys(this.props.survey).length > 0 && this.props.survey.progress !== 100 && !this.state.finished &&
          <React.Fragment>


              {this.props.survey && 
                this.props.survey.modules && 
                !Object.values(this.props.survey.modules)[0].has_glass_effect &&  (

                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {this.props.survey && this.props.survey.modules && Object.keys(this.props.survey.modules).length > 0 && Object.values(this.props.survey.modules)[0].noBackground 
                      ? null 
                      : (
                        this.props.survey.image_url !== '' 
                          ? <BubbleImageBg
                            image={this.props.survey.image_url}
                            duration={100}
                            delay={50}
                            easing="easeInOutElastic"
                          />
                          : <BubbleBg />
                      )
                    }
                  </motion.div>

                )}



              {/* {this.props.survey && 
                this.props.survey.modules && 
                object.keys(this.props.survey.modules).length > 0 &&
                 object.values(this.props.survey.modules)[0].has_glass_effect === true && (
                <h3 classname="o-wrapper__bg-title">{object.values(this.props.survey.modules)[0].title}</h3>
              )} */}


            <div className="o-container">
            {
              this.props.survey && this.props.survey.modules && Object.keys(this.props.survey.modules).length > 0 ?
                <SurveyModulesContainer
                  history={this.props.history}
                  internalization={internalization}
                  modules={this.props.survey.modules}
                  description_modules={this.props.survey.description_modules}
                  onFinished={() => this.setState({finished: true})}
                />
              :
                <SurveyQuestionsContainer
                  survey_token={this.props.match.params.surveyToken}
                  internalization={internalization}
                  onFinished={() => this.setState({finished: true})}
                />
            }
            </div>
          </React.Fragment>
        }
      </section>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    special_organization: state.survey.special_organization    
  };
};

const mapDispatchToProps = {
  fetchSurvey
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyContainer);
