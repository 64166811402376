import React, { useRef, useState, useEffect, useCallback } from "react";

const SortableList = ({
	value: items,
	onChange,
	component: Component,
	itemComponent: ItemComponent,
	itemComponentProps,
	dragHandleDataAttribute,
	animationDuration,
	animationEasing,
  handleClick,
	...rest
}) => {
	const list = useRef()
  const [isDragging, setIsDragging] = useState(false)
	const [dragging, setDragging] = useState()
	const [willEndDragging, setWillEndDragging] = useState()
	const touchId = useRef()
	const dragMoveHandler = useRef()
	const draggedItemPosition = useRef()
	const itemShiftsY = useRef()

	const itemsOrder = useRef()
	const prevItems = useRef()
	if (items !== prevItems.current) {
		prevItems.current = items
		// Reset items order.
		itemsOrder.current = items.map((item, i) => i)
	}

	useEffect(() => {

    lockButtons();
    setTimeout(() => unLockButtons(), 1000)

	}, [])

	const onDragStart = useCallback((node, y, touch) => {

		if (dragging) {
			return
		}
		// The list requires at least two items in order to be sortable.
		if (items.length === 1) {
			return
		}
		const item = getItem(list.current, node, dragHandleDataAttribute)
		if (!item) {
			return
		}
		const [itemNode, position] = item
		setDragging({
			touch,
			initialPosition: position,
			// Using `.getBoundingClientRect()` instead of `.offsetHeight`/`.offsetTop`
			// because `.offsetXxx` values don't know how to work with fractional pixels.
			// Fractional pixels (for example, `0.5`) are used on "retina" screens.
			itemHeights: Array.prototype.map.call(list.current.childNodes, node => node.getBoundingClientRect().height),
			itemSpacing: list.current.childNodes[1].getBoundingClientRect().top - list.current.childNodes[0].getBoundingClientRect().bottom,
			itemTopOffset: itemNode.getBoundingClientRect().top - list.current.childNodes[0].getBoundingClientRect().top,
			dragStartY: y
		})
		draggedItemPosition.current = {
			previous: position,
			new: position,
			shiftY: 0
		}
		itemShiftsY.current = items.map(_ => 0)

    setIsDragging(true)

	}, [dragging, isDragging])

	const onMouseDown = useCallback((event) => {

    if(isDragging) return;

		// Left mouse button only.
		if (event.button !== 0) {
			return
		}
		onDragStart(event.target, event.pageY)
	}, [onDragStart, isDragging])

	const onTouchStart = useCallback((event) => {

    document.body.style.overflow = 'hidden'
		// Single touch only.
		if (event.touches.length > 1) {
			return
		}
		const touch = event.changedTouches[0]
		onDragStart(event.target, touch.pageY, touch.identifier)
	}, [onDragStart])

	const onDragMove = useCallback((event) => {
		if (!dragging) {
			return
		}

		let y
		if (dragging.touch !== undefined) {
			for (const touch of event.changedTouches) {
				if (touch.identifier === dragging.touch) {
					y = touch.pageY
					break
				}
			}
		} else {
			y = event.pageY
		}

		if (y === undefined) {
			return
		}

		event.preventDefault()

		const movedY = y - dragging.dragStartY
		const draggedItemOffsetTop = dragging.itemTopOffset + movedY

		const position = getDraggedItemPosition(
			dragging.itemHeights,
			dragging.itemSpacing,
			draggedItemOffsetTop,
			dragging.initialPosition
		)

		const draggedItemHeight = dragging.itemHeights[dragging.initialPosition]

		// Update list items' positions.
		itemShiftsY.current = items.map((_, j) => {
			if (j < dragging.initialPosition) {
				if (j >= position) {
					return draggedItemHeight + dragging.itemSpacing
				} else {
					return 0
				}
			} else if (j > dragging.initialPosition) {
				if (j <= position) {
					return -1 * (draggedItemHeight + dragging.itemSpacing)
				} else {
					return 0
				}
			} else {
				return movedY
			}
		})

		// Apply item shifts Y.
		let i = 0
		while (i < items.length) {
			list.current.childNodes[i].style.transform = `translateY(${itemShiftsY.current[i]}px)`
			i++
		}

		draggedItemPosition.current = {
			previous: dragging.initialPosition,
			new: position,
			shiftY: getDraggedItemPositionY(
				dragging.itemHeights,
				dragging.itemSpacing,
				dragging.initialPosition,
				position
			) - getDraggedItemPositionY(
				dragging.itemHeights,
				dragging.itemSpacing,
				dragging.initialPosition,
				dragging.initialPosition
			)
		}
	}, [dragging])

	const onDragEnd = useCallback(() => {
		setDragging()
		setWillEndDragging(true)
		const newItemsOrder = getNewItemsOrder(
			itemsOrder.current,
			draggedItemPosition.current.previous,
			draggedItemPosition.current.new
		)

    lockButtons();

		setTimeout(() => {
      let finalItems = []
			setWillEndDragging(false)
			itemsOrder.current = newItemsOrder

      onChange(newItemsOrder.map(i =>  {
        finalItems.push(items[i])
        return items[i]
      }))

      handleClick(finalItems);
      document.body.style.overflow = 'initial'

      setTimeout(() => {
        setIsDragging(false);
        unLockButtons();
      }, 200)
		}, 500 )


	}, [itemsOrder.current, isDragging])

  const lockButtons = () => {
    const buttons = document.querySelectorAll(".c-arrow-button")
    document.querySelector(".c-item-format-style-15__list").style.opacity = ".1";
    document.querySelector(".c-item-format-style-15__list").style.pointerEvents = "none";
    buttons.forEach(b => {
      b.style.opacity = ".1";
      b.style.pointerEvents = "none";
    })
  }
  const unLockButtons = () => {
    const buttons = document.querySelectorAll(".c-arrow-button")
    document.querySelector(".c-item-format-style-15__list").style.opacity = "1";
    document.querySelector(".c-item-format-style-15__list").style.pointerEvents = "all";
    buttons.forEach(b => {
      b.style.opacity = "1";
      b.style.pointerEvents = "all";
    })
  }

	const onMouseUp = useCallback((event) => {
		if (event.which !== 1) {
			return
		}
		onDragEnd()

	}, [onDragEnd])

	const onTouchEnd = useCallback((event) => {
		for (const touch of event.changedTouches) {
			if (touch.identifier === touchId.current) {
				onDragEnd()
				return
			}
		}
    document.removeEventListener('touchmove', dragMoveHandler.current, { passive: false })
	}, [onDragEnd])

	useEffect(() => {
		if (dragging) {
			dragMoveHandler.current = onDragMove
			if (dragging.touch !== undefined) {
				touchId.current = dragging.touch
				window.addEventListener('touchmove', dragMoveHandler.current, { passive: false })
				window.addEventListener('touchend', onTouchEnd)
			} else {
				window.addEventListener('mousemove', dragMoveHandler.current, { passive: false })
				window.addEventListener('mouseup', onMouseUp)
			}
		} else {
			if (touchId.current !== undefined) {
				touchId.current = undefined
				window.removeEventListener('touchmove', dragMoveHandler.current, { passive: false })
				window.removeEventListener('touchend', onTouchEnd)
			} else {
				window.removeEventListener('mousemove', dragMoveHandler.current, { passive: false })
				window.removeEventListener('mouseup', onMouseUp)
			}
			dragMoveHandler.current = undefined
		}
	}, [dragging])

	useEffect(() => {
		if (willEndDragging) {
			// Reset dragged item position.
			list.current.childNodes[draggedItemPosition.current.previous].style.transform = `translateY(${
				draggedItemPosition.current.shiftY
			}px)`
		}
	}, [willEndDragging])

	return (
		<Component
			{...rest}
			ref={list}
			onTouchStart={onTouchStart}
			onMouseDown={onMouseDown}>
			{itemsOrder.current.map((i, position) => (
				<ItemComponent
					{...itemComponentProps}
					key={i}
					dragging={dragging ? true : false}
					dragged={dragging && position === draggedItemPosition.current.previous}
					style={(dragging || willEndDragging) ? getItemStyle(
						position === draggedItemPosition.current.previous,
						willEndDragging,
						itemShiftsY.current[position],
						animationDuration,
						animationEasing
					) : TRANSFORM_NONE}>
					{items[i]}
				</ItemComponent>
			))}
		</Component>
	)
}

const TRANSFORM_NONE = { transform: 'none' }

function getItemStyle(isDragged, willEndDragging, shiftY, animationDuration, animationEasing) {
	const style = {
		// `position: relative` is for `z-index` to work.
		position: 'relative',
		transition: `all ${animationDuration}ms ${animationEasing}`
	}
	if (isDragged) {
		style.zIndex = 1
		if (!willEndDragging) {
			style.transition = undefined
		}
	} else {
		style.transform = `translateY(${shiftY}px)`
	}
	return style
}

const ListComponent = React.forwardRef(({ children, ...rest }, ref) => (
	<div ref={ref} {...rest}>
		{children}
	</div>
))


SortableList.defaultProps = {
	component: ListComponent,
	animationDuration: 200,
	animationEasing: 'ease-out'
}


// Interactive elements aren't draggable.
const IGNORE_CLICKS_INSIDE_TAGS = [
	'a',
	'button',
	'input',
	'textarea',
	'select'
]

/**
 * Returns the list item that has been clicked (along with its index).
 * @param {Element} list
 * @param {Element} node — The DOM Element that has been clicked.
 * @param {string} [dragHandleDataAttribute] — Drag handle data attribute.
 * @return {any[]} [result] — An array having shape `[item, i]`.
 */
function getItem(list, node, dragHandleDataAttribute) {
	let handle
	let childNode
	while (node) {
		if (node === list) {
			// Clicked outside of a handle.
			if (dragHandleDataAttribute && !handle) {
				return
			}
			if (childNode) {
				let i = 0
				while (i < node.childNodes.length) {
					if (node.childNodes[i] === childNode) {
						return [childNode, i]
					}
					i++
				}
			}
			break
		}
		if (IGNORE_CLICKS_INSIDE_TAGS.indexOf(node.tagName.toLowerCase()) >= 0) {
			return
		}
		if (node.dataset[dragHandleDataAttribute]) {
			handle = node
		}
		childNode = node
		node = node.parentElement
	}
}

function getDraggedItemPosition(itemHeights, itemSpacing, draggedItemOffsetTop, initialPosition) {
	const scanLineY = draggedItemOffsetTop + itemHeights[initialPosition] / 2 + itemSpacing / 2
	let y = 0
	let i = 0
	let subtractOwnPosition = 0
	while (i < itemHeights.length) {
		y += itemHeights[i] + itemSpacing
		if (scanLineY <= y) {
			return i
		}
		i++
	}
	return itemHeights.length - 1
}

function getDraggedItemPositionY(itemHeights, itemSpacing, initialPosition, position) {
	let top = 0
	let j = 0
	while (j < position) {
		if (j === initialPosition) {
			position++
		} else {
			top += itemHeights[j] + itemSpacing
		}
		j++
	}
	return top
}

function getNewItemsOrder(itemsOrder, fromPosition, toPosition) {
	if (toPosition < fromPosition) {
		return itemsOrder.slice(0, toPosition)
			.concat(itemsOrder[fromPosition])
			.concat(itemsOrder.slice(toPosition, fromPosition))
			.concat(itemsOrder.slice(fromPosition + 1))
	}
	if (toPosition > fromPosition) {
		return itemsOrder.slice(0, fromPosition)
			.concat(itemsOrder.slice(fromPosition + 1, toPosition + 1))
			.concat(itemsOrder[fromPosition])
			.concat(itemsOrder.slice(toPosition + 1))
	}
	return itemsOrder.slice()
}

const ItemComponent = ({
  dragging,
  dragged,
  children: { text, img }, 
  ...rest 
}) => {
  return (
    <div {...rest} className={`list__item ${dragged ? 'list__item--dragged' : ''}`}>
      <div className="list__item-content">
        <div className="list__item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="68" viewBox="0 0 32 68" fill="none"><path d="M12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7ZM12.5 25C12.5 28.0376 10.0376 30.5 7 30.5C3.96243 30.5 1.5 28.0376 1.5 25C1.5 21.9624 3.96243 19.5 7 19.5C10.0376 19.5 12.5 21.9624 12.5 25ZM12.5 43C12.5 46.0376 10.0376 48.5 7 48.5C3.96243 48.5 1.5 46.0376 1.5 43C1.5 39.9624 3.96243 37.5 7 37.5C10.0376 37.5 12.5 39.9624 12.5 43ZM12.5 61C12.5 64.0376 10.0376 66.5 7 66.5C3.96243 66.5 1.5 64.0376 1.5 61C1.5 57.9624 3.96243 55.5 7 55.5C10.0376 55.5 12.5 57.9624 12.5 61ZM30.5 7C30.5 10.0376 28.0376 12.5 25 12.5C21.9624 12.5 19.5 10.0376 19.5 7C19.5 3.96243 21.9624 1.5 25 1.5C28.0376 1.5 30.5 3.96243 30.5 7ZM30.5 25C30.5 28.0376 28.0376 30.5 25 30.5C21.9624 30.5 19.5 28.0376 19.5 25C19.5 21.9624 21.9624 19.5 25 19.5C28.0376 19.5 30.5 21.9624 30.5 25ZM30.5 43C30.5 46.0376 28.0376 48.5 25 48.5C21.9624 48.5 19.5 46.0376 19.5 43C19.5 39.9624 21.9624 37.5 25 37.5C28.0376 37.5 30.5 39.9624 30.5 43ZM30.5 61C30.5 64.0376 28.0376 66.5 25 66.5C21.9624 66.5 19.5 64.0376 19.5 61C19.5 57.9624 21.9624 55.5 25 55.5C28.0376 55.5 30.5 57.9624 30.5 61Z" stroke="currentcolor" stroke-width="3"></path></svg>
        </div>
        {img && (
          <div className="list__item-img">
            <img src={img} />
          </div>
        )}
        {text && (
          <div className="list__item-title">
            <span>{text}</span>
          </div>
        )}
      </div>
      <div style={{ display: 'none' }} className="list__item-handle"></div>
    </div>
  )
}

const SurveyOrderItem = (props) => {

  const [items, setItems] = useState( Object.keys(props.selectedItems).length > 0 ? Object.values(props.selectedItems) : Object.values(props.questionData.items) ); // items state

  return (
    <>

      {props.questionData.item_context && (
        <p className="c-item-format-style-12__item-context">{props.questionData.item_context}</p>
      )}

      {props.questionData.img_context && (
        <img
          className="c-item-format-style-12__item-context-img"
          src={props.questionData.img_context}
        />
      )}

      {props.questionData.title && props.questionData.intro_title && (
        <p className="c-item-format-style-12__item-title">{props.questionData.title}</p>
      )}


      <div className="">
        <SortableList 
          className="c-item-format-style-15__list" 
          itemComponent={ItemComponent}
          value={items.filter((e) => e.hasOwnProperty("id"))}
          handleClick={props.handleClick}
          onChange={setItems}/>
      </div>
    </>
  );
};

export default SurveyOrderItem;
