import React from "react";
import Checkbox from "../../../../components/Checkbox";

const SurveyCheckboxImageItem = (props) => {


  const _handleCheckbox = (index, data) => {
    return props.handleClick({ [index]: data });
  };


  const _isSelected = (index) => {
    return Object.keys(props.selectedItems).includes(index.toString())  
  }

  return (
    <>
      {props.questionData.item_context && (
        <p className="c-item-format-style-12__item-context">{props.questionData.item_context}</p>
      )}
      {props.questionData.img_context && (
        <img
          className="c-item-format-style-12__item-context-img"
          src={props.questionData.img_context}
        />
      )}
      {props.questionData.title && props.questionData.intro_title && (
        <p className="c-item-format-style-12__item-title">{props.questionData.title}</p>
      )}
      <ul className="c-item-format-style-12__item-list">
        {Object.values(props.questionData.items).map((item, index) => {
          return (
            <li key={`${item.text}-${index}`} className="o-margin--bottom-size-s o-display--flex">
                <Checkbox 
                  // text={item.name || item.text}
                  text={''}
                  value={item.value}
                  onClick={() => _handleCheckbox(index, item)}
                  checked={_isSelected(index)}
                  className="o-display--flex"
                  groupClass="c-checkbox__tick-illustration-color--primary"
                  // correct={_isCorrect()}
                  item_format_style={props.item_format_style}
                />
                {item && item.img && (<img src={item.img} alt={item.text} className="c-item-format-style-12__item-image" />)}
                {item && item.text && (<p className="c-item-format-style-12__item-text">{item.text}</p>)}
            </li>
          )
        })}
      </ul>
    </>
  )


};

export default SurveyCheckboxImageItem;
